const firebaseClient= ({
    apiKey: "AIzaSyBOH6_ZKrAIE56ehDNw6xihf3kjZr4_kjw",
    authDomain: "lifedesign-form.firebaseapp.com",
    databaseURL: "https://lifedesign-form-default-rtdb.firebaseio.com",
    projectId: "lifedesign-form",
    storageBucket: "lifedesign-form.appspot.com",
    messagingSenderId: "575119971356",
    appId: "1:575119971356:web:2f4f85d2dc7e0823407bd9",
    measurementId: "G-KH44Z50HNB"
});

export default firebaseClient;